import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/build/cjs/http.impl';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { PlanListWidgetRole } from '../../constants/elements';
import { plansFixtureTranslated } from '../../fixtures';
import { uncompressUuidArray } from '../../services/uuid-compression';
import { toError } from '../../utils/errors';
import model from './model';

type WidgetState = Extract<
  PlanListWidgetRole,
  | PlanListWidgetRole.EmptyState
  | PlanListWidgetRole.PlansInfoState
  | PlanListWidgetRole.LoadingState
  | PlanListWidgetRole.ErrorState
>;

const PLAN_VARIANT_STATE_TO_WIDGET_VARIANT_ID: Record<string, string> = {
  [PlanListWidgetRole.PlanVariantDefaultState]: `#${PlanListWidgetRole.PlanWidgetDefault}`,
  [PlanListWidgetRole.PlanVariantHighlightedState]: `#${PlanListWidgetRole.PlanWidgetHighlighted}`,
  [PlanListWidgetRole.PlanVariantCustomState]: `#${PlanListWidgetRole.PlanWidgetCustom}`,
};

export default model.createController(({ $w, $widget, flowAPI }) => {
  const getDemoPlansIfEditor = () => {
    return flowAPI.environment.isEditor ? plansFixtureTranslated(flowAPI.translations.t) : null;
  };

  const getOrderedPlans = async (planIds?: string[]) => {
    if (!planIds?.length) {
      return getDemoPlansIfEditor();
    }

    const response = await flowAPI.httpClient.request(listPublicPlans({ planIds }));
    return response.data.plans
      ? response.data.plans.sort((a, b) => (planIds.indexOf(a.id!) > planIds.indexOf(b.id!) ? 1 : -1))
      : getDemoPlansIfEditor();
  };

  const showState = (state: WidgetState) => {
    $w(`#${PlanListWidgetRole.PlanListStates}`).changeState(state);
  };

  const setWidgetData = (plans: PublicPlan[]) => {
    $w(`#${PlanListWidgetRole.PlanList}`).onItemReady(($item, itemData) => {
      $item(`#${PlanListWidgetRole.PlanVariantBox}`).changeState(itemData.state);
      const planId = PLAN_VARIANT_STATE_TO_WIDGET_VARIANT_ID[itemData.state];
      $item(planId).setPlan(itemData.plan);
    });

    $w(`#${PlanListWidgetRole.PlanList}`).data = plans.map((plan, i) => ({
      _id: plan.id!,
      plan,
      state: PlanListWidgetRole.PlanVariantDefaultState,
    }));

    showState(PlanListWidgetRole.PlansInfoState);
  };

  return {
    pageReady: async () => {
      const isWidgetRendered = $w(`#${PlanListWidgetRole.PlanList}`).rendered;
      if (flowAPI.environment.isEditor && !isWidgetRendered) {
        return;
      }

      if (!flowAPI.environment.isEditor && !flowAPI.environment.isSSR) {
        showState(PlanListWidgetRole.LoadingState);
      }

      try {
        const { props } = $widget;
        const plans = await getOrderedPlans(props.planIds ? uncompressUuidArray(props.planIds.split(',')) : undefined);
        if (!plans?.length) {
          return showState(PlanListWidgetRole.EmptyState);
        }

        setWidgetData(plans);
      } catch (e) {
        showState(PlanListWidgetRole.ErrorState);
        flowAPI.errorMonitor?.captureException(toError(e));
      }
    },
    exports: {},
  };
});
